$gutter: .5rem; //for primeflex grid system

@import "node_modules/primeng/resources/primeng.min.css";
@import "node_modules/primeflex/primeflex.scss";
@import "node_modules/primeicons/primeicons.css";
@import "node_modules/prismjs/themes/prism-coy.css";
/* Importing Bootstrap SCSS file. */
@import "node_modules/bootstrap/dist/css/bootstrap.css";

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";

@import 'node_modules/quill/dist/quill.core.css';
@import 'node_modules/quill/dist/quill.bubble.css';
@import 'node_modules/quill/dist/quill.snow.css';

* {
  font-family: sans-serif;
}

a {
  text-decoration: none !important;
  color: #2e3440;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-item > ul > li.profile-submenu-header .profile img,
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-item > a .profile-image-wrapper img {
  border-radius: 50%;
}

::-webkit-scrollbar {
  width: 6px;
  height: 12px;
  overflow-y: scroll;
  background: #fff;
  box-shadow: inset 0 0 4px #ffffff;
}

::-webkit-scrollbar-thumb {
  background: #415464;
  border-radius: 10px;
}

.layout-sidebar .layout-menu-container .layout-menu {
  padding-bottom: 20px;
}

.layout-breadcrumb {
  padding: 0 30px;
}

.breadcrumb {
  margin-bottom: 0px;
}

/* Importing Bootstrap SCSS file. */

.full-width,
.full-width-mobile {
  width: 100%;
  display: block;
}

@media (min-width: 768px) {
  .full-width-mobile {
    width: auto;
    display: inline-block;
  }
}

.layout-breadcrumb .breadcrumb .route-bar-breadcrumb {
  justify-content: flex-start;
}

.danger-hint {
  color: #db5858;
}

.text-center {
  text-align: center;
}

.align-middle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.p-dropdown {
  width: 100% !important;
}

@media screen and (min-width: 768px) {
  .forgot-pass-dialog.p-dialog {
    width: 600px !important;
  }
  .details-dialog.p-dialog {
    width: 800px;
  }
}

.ngx-phone {
  height: 37px;
  color: #495057;
  border: 1px solid #ced4da;
  border-radius: 4px;
  width: 100%;
}

.iti.iti--allow-dropdown {
  width: 100%;
}

.contract-checkbox.p-togglebutton {
  margin-left: 10px !important;
}

.contract-checkbox.p-selectbutton .p-button.p-highlight {
  background-color: #415464 !important;
  border-color: #415464 !important;
  color: #fff;
}

.contract-checkbox.p-selectbutton .p-button {
  background-color: #fff;
  border-color: #415464;
  color: #415464;
}

.contract-checkbox.p-selectbutton .p-button:hover {
  background-color: #415464 !important;
}
.language-checkbox.p-togglebutton {
  margin-left: 10px !important;
}

.language-checkbox.p-selectbutton .p-button.p-highlight {
  background-color: #415464 !important;
  border-color: #415464 !important;
  color: #fff;
}

.language-checkbox.p-selectbutton .p-button {
  background-color: #fff;
  border-color: #415464;
  color: #415464;
  padding: 5px;
  font-size: 11px;
}

.language-checkbox.p-selectbutton .p-button:hover {
  background-color: #415464 !important;
}

.language-switch.p-togglebutton, .html-switch.p-togglebutton {
  margin-left: 10px !important;
}

.language-switch.p-selectbutton {
  float: right !important;
}
.html-switch.p-selectbutton {
  float: left !important;
}

.language-switch.p-selectbutton .p-button.p-highlight, .html-switch.p-selectbutton .p-button.p-highlight {
  background-color: #415464 !important;
  border-color: #415464 !important;
  color: #fff;
  padding: 3px !important;
}

.language-switch.p-selectbutton .p-button, .html-switch.p-selectbutton .p-button {
  background-color: #fff;
  border-color: #415464;
  color: #415464;
  padding: 3px !important;
}

.language-switch.p-selectbutton .p-button:hover, .html-switch.p-selectbutton .p-button:hover {
  background-color: #415464 !important;
}

.layout-breadcrumb {
  background-color: #ededed;
}

.layout-topbar {
  background-color: #fcfcfc;
}

.layout-content {
  background-color: #fcfcfc;
}

.p-datatable .p-datatable-header {
  background-color: #fff;
  border-color: #fff;
  padding: 0px;
  margin-bottom: 5px;
}

.p-datatable .p-datatable-thead > tr > th {
  border: none;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.details-dialog.p-dialog .p-dialog-header {
  border: none;
}

.p-treeselect {
  background: #ffffff;
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 3px;
  width: 100%;
}

.p-treeselect:not(.p-disabled):hover {
  border-color: #2196F3;
}

.p-treeselect:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
  border-color: #2196F3;
}

.p-treeselect .p-treeselect-label {
  padding: 0.5rem 0.5rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.p-treeselect .p-treeselect-label.p-placeholder {
  color: #6c757d;
}

.p-treeselect.p-treeselect-chip .p-treeselect-token {
  padding: 0.25rem 0.5rem;
  margin-right: 0.5rem;
  background: #dee2e6;
  color: #495057;
  border-radius: 16px;
}

.p-treeselect .p-treeselect-trigger {
  background: transparent;
  color: #6c757d;
  width: 2.357rem;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.p-treeselect.p-invalid.p-component {
  border-color: #f44336;
}

.p-inputwrapper-filled .p-treeselect.p-treeselect-chip .p-treeselect-label {
  padding: 0.25rem 0.5rem;
}

.p-treeselect-panel {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  border-radius: 3px;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.p-treeselect-panel .p-treeselect-items-wrapper .p-tree {
  border: 0 none;
}

.p-treeselect-panel .p-treeselect-items-wrapper .p-treeselect-empty-message {
  padding: 0.5rem 1rem;
  color: #495057;
  background: transparent;
}

.p-input-filled .p-treeselect {
  background: #f8f9fa;
}

.p-input-filled .p-treeselect:not(.p-disabled):hover {
  background-color: #f8f9fa;
}

.p-input-filled .p-treeselect:not(.p-disabled).p-focus {
  background-color: #ffffff;
}

.p-image img {
  border-radius: 5px !important;
}

/* PRIMENG IMAGE COMPONENTS STYLING */
/* bY: ISLAM */
.p-image-toolbar {
  margin: 20px;

  .p-image-action {
    margin-left: 20px;
    color: #fff;

    i {
      font-size: 24px;
    }
  }
}

.p-image-mask {
  background-color: rgba(0, 0, 0, 0.8);
}

.p-image-preview-indicator {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  border-bottom: 3px solid gray;

  i {
    color: #ffffff;
  }
}

/* PRIMENG IMAGE COMPONENTS STYLING */
.p-multiselect {
  width: 100% !important;
}

.switch {
  margin-top: 28px;
}

.autocomplete {
  span {
    width: 100% !important;

    input, ul {
      width: 100% !important;
    }
  }
}

.dropdown-item {
  cursor: pointer;
}

.p-fileupload-row div button {
  width: 2.357rem !important;
}

// Full Calendar styles
.fc .fc-view-container th {
  background: #efefef;
  border: 1px solid #dee2e6;
  color: #212529;
}

.fc .fc-view-container td.fc-widget-content {
  background: #ffffff;
  border: 1px solid #dee2e6;
  color: #212529;
}

.fc .fc-view-container td.fc-head-container {
  border: 1px solid #dee2e6;
}

.fc .fc-view-container .fc-row {
  border-right: 1px solid #dee2e6;
}

.fc .fc-view-container .fc-event {
  background: #51749e;
  border: 1px solid #51749e;
  color: #ffffff;
}

.fc .fc-view-container .fc-divider {
  background: #efefef;
  border: 1px solid #dee2e6;
}

.fc .fc-toolbar .fc-button {
  color: #ffffff;
  background: #415464;
  border: 1px solid #415464;
  font-size: 1rem;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
  align-items: center;
  text-transform: capitalize;
}

.fc .fc-toolbar .fc-button:enabled:hover {
  background: #51749e;
  color: #ffffff;
  border-color: #51749e;
}

.fc .fc-toolbar .fc-button:enabled:active {
  background: #48678c;
  color: #ffffff;
  border-color: #48678c;
}

.fc .fc-toolbar .fc-button:enabled:active:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfcdde;
}

.fc .fc-toolbar .fc-button .fc-icon-chevron-left {
  font-family: "PrimeIcons" !important;
  text-indent: 0;
  font-size: 1rem;
}

.fc .fc-toolbar .fc-button .fc-icon-chevron-left:before {
  content: "";
}

.fc .fc-toolbar .fc-button .fc-icon-chevron-right {
  font-family: "PrimeIcons" !important;
  text-indent: 0;
  font-size: 1rem;
}

.fc .fc-toolbar .fc-button .fc-icon-chevron-right:before {
  content: "";
}

.fc .fc-toolbar .fc-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfcdde;
}

.fc .fc-toolbar .fc-button.fc-dayGridMonth-button, .fc .fc-toolbar .fc-button.fc-timeGridWeek-button, .fc .fc-toolbar .fc-button.fc-timeGridDay-button {
  background: #415464;
  border: 1px solid #415464;
  color: #ffffff;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}

.fc .fc-toolbar .fc-button.fc-dayGridMonth-button:hover, .fc .fc-toolbar .fc-button.fc-timeGridWeek-button:hover, .fc .fc-toolbar .fc-button.fc-timeGridDay-button:hover {
  background: #51749e;
  border-color: #51749e;
  color: #ffffff;
}

.fc .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active, .fc .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active, .fc .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active {
  background: #545b62;
  border-color: #4e555b;
  color: #ffffff;
}

.fc .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active:hover, .fc .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active:hover, .fc .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active:hover {
  background: #545b62;
  border-color: #4e555b;
  color: #ffffff;
}

.fc .fc-toolbar .fc-button.fc-dayGridMonth-button:focus, .fc .fc-toolbar .fc-button.fc-timeGridWeek-button:focus, .fc .fc-toolbar .fc-button.fc-timeGridDay-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfcdde;
  z-index: 1;
}

.fc .fc-toolbar .fc-toolbar-chunk > div > .fc-button {
  border-radius: 0;
}

.fc .fc-toolbar .fc-toolbar-chunk > div > .fc-button:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.fc .fc-toolbar .fc-toolbar-chunk > div > .fc-button:last-of-type {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.fc .fc-toolbar .fc-button.fc-activity-button, .fc .fc-toolbar .fc-button.fc-saeson-button {
  margin-right: 5px;
}

.fc .fc-toolbar-title {
  display: inline-block;
  margin-left: 5px !important;
  font-size: 1.4em !important;
}

.fc-header-toolbar .fc-toolbar-chunk > div {
  display: flex;
  align-items: center;
}

.calendar-form .p-dialog-content {
  overflow-y: visible;
}

.calendar-dialog .p-calendar {
  width: 100%;
}

@media (max-width: 669px) {
  .fc-header-toolbar {
    flex-wrap: wrap;
  }
  .fc-toolbar-chunk {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0px;
    }
  }
}

.fc-event:hover {
  cursor: pointer;
}

// Grid
@media (min-width: 576px) {
  .p-md-col {
    flex-grow: 1;
    flex-basis: 0;
    padding: 0.5rem;
  }
}

@media (min-width: 992px) {
  .p-lg-col {
    flex-grow: 1;
    flex-basis: 0;
    padding: 0.5rem;
  }
}

.p-md-5 {
  padding: 0.5rem !important;
}

.status-td {
  display: flex;
  align-items: center;
}

.status-txt {
  margin-left: 12px;
}

.dropdown-menu {
  top: -18px !important;
  right: 45px !important;
  padding: 5px !important;
}

.hide-orderlist-controls .p-orderlist-controls {
  display: none;
}

.p-datatable-scrollable .p-datatable-wrapper,
.p-calendar {
  width: 100%;
}

.services-fieldset .p-fieldset-legend {
  width: fit-content !important;
  margin-left: 15px;
  padding: 10px 0 !important;
  font-weight: 100 !important;
  border: none !important;
  font-size: 14px !important;
  background-color: transparent;

  span {
    margin: 0 10px;
  }
}

// Enquiry list styles
.enquiry-list {
  .p-panel .p-panel-header {
    background-color: transparent;
    border-radius: 10px;
    padding: 10px 20px;
  }

  .p-panel .p-panel-content {
    border: 0;
    padding: 20px 0;
  }
}

.view-company-link {
  margin-top: 8px;
  text-decoration: underline !important;
  margin-right: 30px;
  color: #007bff;
  float: right;
  cursor: pointer;
}

.word-break {
  word-break: break-all;
}

.rem-padding {
  padding: 1rem !important;
}

.half-rem-padding {
  padding: 0.5rem !important;
}

.invalid-controls li {
  color: red;
  margin: 3px 0;
  font-size: 12px;
}

.invalid-controls li:first-letter {
  text-transform: uppercase;
}

.request-products-input {
  border: 2px solid #415464 !important;
}

.request-card {
  margin-top: 10px;
  border: 1px solid rgba(94, 129, 172, 0.72);
  float: left;
  width: 100%;
}

.tee-view-card {
  margin-top: 10px;
  float: left;
  width: 100%;
}

.participants-table td, .participants-table th {
  padding: 5px 5px 5px 10px !important;
}

.participants-table th {
  background-color: #f9f9f9 !important;
}

.participants-table {
  border: 1px solid #dee2e6 !important;
}

.alternative-table td {
  background-color: #f9f9f9;
}

.hint {
  text-align: center;
  color: gray;
}

.notifications-list {
  height: 80vh;
  overflow-y: scroll;
}

.notifications-title {
  font-size: 11px !important;
}

.notifications-body {
  font-size: 10px !important;
}

.notifications-item {
  border-bottom: 1px solid #f2f2f2 !important;
}

.notifications-time {
  font-size: 9px !important;
  float: right;
}

.notifications-user {
  font-size: 9px !important;
  float: left;
}

.notifications-icon {
  float: left;
  margin-right: 4px;
  font-size: 11px;
}

.bold-font {
  font-weight: bold;
}

.svg-color-one {
  fill: green !important;
}

.p-badge.p-badge-warning {
  background-color: transparent !important;
  color: #ffc107 !important;
}

.gray-bg {
  background-color: #fafafa;
}

.mt-40 {
  margin-top: 40px;
}

.player-panel {
  margin-bottom: 10px !important;
}

.player-panel .p-panel-footer {
  height: 52px !important;
}

.request-sections-panel .p-accordion-tab {
  margin-bottom: 5px !important;
}

.request-sections-panel .p-accordion-header-link {
  background: #415464 !important;
}

.request-sections-panel .p-accordion-header {
  opacity: 1;
}

.request-sections-panel .p-accordion-header-text, .request-sections-panel .p-accordion-toggle-icon {
  color: #FFFFFF !important;
}

.request-detail-card {
  background-color: #415464 !important;
  color: #FFFFFF;
}

.destination-card {
  border-color: #415464;
}

.white-color {
  color: #FFFFFF;
}

.request-divider .p-divider-content {
  border-radius: 5px !important;
  width: 150px !important;
}

.search-div {
  background-color: #415464;
  padding-top: 16px;
  border-radius: 5px;
  float: left;
  width: 100%;
}

.app-name {
  font-size: 19px !important;
}

.green-button {
  background-color: #7CFAC3 !important;
  border: 1px solid #7CFAC3 !important;
  color: #415464 !important;
}

.request-month-calendar .p-datepicker-group-container {
  display: none !important;
}
.log-card .p-card-content {
  padding: 0 !important;
}
.text-left {
  text-align: left;
}
.tab-menu {
  margin-bottom: 15px !important;
}
.timepicker-dial__control {
  text-align: center !important;
}
.timepicker-overlay {
  z-index: 10000000 !important;
}

.timepicker-backdrop-overlay {
  z-index: 10000000 !important;
}
.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
  white-space: nowrap !important;
  margin-top: 1px !important;
}
.request-input-num .p-inputnumber-input {
  width: 50px;
}
.request-input-mask {
  width: 70px;
}
.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
  border-radius: 50%;
}
:host ::ng-deep .p-multiselect {
  min-width: 15rem;
  width: 18rem;
}

:host ::ng-deep .multiselect-custom .p-multiselect-label {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.country-item-value {
  padding: .25rem .5rem;
  border-radius: 3px;
  display: inline-flex;
  margin-right: .5rem;
  background-color: var(--primary-color);
  color: var(--primary-color-text);
}
.country-item-value img.flag {
  width: 17px;
}


.country-placeholder {
  padding: 0.25rem;
}
.custom-multi-select.p-multiselect .p-multiselect-label {
  padding: 0.25rem 0.75rem !important;
}
.input-hint {
  color: red;
  float: right;
  margin-top: 5px;
}
.create-icon {
  color: #415464;
  cursor: pointer;
  float: right;
  margin-top: 5px;
}
.filter-petrol {
  filter: invert(30%) sepia(4%) saturate(3104%) hue-rotate(166deg) brightness(91%) contrast(83%);
}
.filter-white {
  filter: invert(94%) sepia(100%) saturate(0%) hue-rotate(207deg) brightness(106%) contrast(109%);
}
.lang-title {
  margin: 8px 0;
  width: 63%;
  .lang-icon {
    float: left;
    margin-right: 5px;
    color: #a3be8c;
    font-size: 18px;
  }
}
.hotel-tri-check .p-checkbox-box {
  display: inline-block !important;
  margin-top: -7px !important;
}
.p-galleria .p-galleria-close {
  margin: 0.5rem;
  background: transparent;
  color: #efefef;
  width: 4rem;
  height: 4rem;
  transition: box-shadow 0.15s;
  border-radius: 4px;
  top: 68px;
}


